/* eslint-disable react/display-name */
import React from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import ImageCropper from "components/ImageCropper";

const useStyles = makeStyles({
  DragAndDropContainer: {
    width: 140,
    height: 'auto',
    margin: 12,
    border: "1px dashed lightgray",
    cursor: 'pointer',
    justify: "center",
    alignItems: "center"
  },
  dragText: {
    borderBottom: "1px dashed lightgray",
  },
  cardImageTop: {
    width: 'inherit',
    height: 78,
    objectFit:'cover',
    margin: 'auto',
    textAlign: 'center',
    display: 'block',
  },
})

const ImageUploaderWithCropper = props => {
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [imageData, setImageData] = React.useState([]);
  const [cropper, setCropper] = React.useState({
    openCropper: false,
    imageUrl: "",
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/png", "image/bmp", "image/svg+xml"],
    multiple: false,
    onDrop: acceptedFiles => {
      const data = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
      setFiles(data);
      handleCropper(data)
    },
  });

  const handleCropper = (Files) => {

    setCropper({
      ...cropper,
      openCropper: true,
      imageUrl: Files[0].preview
    })
  }

  const handleCropImage = (value) => {
    let imgData = value.blob
    const milliseconds = new Date().getMilliseconds()
    const imageName = milliseconds + files[0].name
    const pathName = milliseconds + files[0].path
    imgData.name = imageName
    imgData.path = pathName
    imgData.originalname = imageName
    
    setImageData(imgData)
    props.onHandleChange(imgData)
  }

  const closeCropModal = () => {
    setCropper({
      ...cropper,
      openCropper: false,
    })
  }

  const renderIconImage = () => {
    if(imageData && imageData.preview) {
      return <img className={classes.cardImageTop} style={{cursor: 'auto'}} src={imageData.preview} alt="body-type" />
    }
  }
  
  const { aspectRatio } = props;
  return  (
    <div>
      <div
        className={classes.DragAndDropContainer}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" align="center" className={classes.dragText}>
          Drag &apos;n&apos; drop some files here, or click to select
          files.
        </Typography>
        {renderIconImage()}
      </div>
       <ImageCropper
        previewImg={cropper.imageUrl && cropper.imageUrl}
        isOpen={cropper.openCropper}
        aspectRatio={aspectRatio}
        handleClose={closeCropModal}
        handleCrop={handleCropImage}
      />
    </div>
  )
}

export default ImageUploaderWithCropper;