import { combineReducers } from 'redux';
import auth from './auth';
import vehicles from './vehicles';
import pricelist from './prices';
import discounts from './discounts';
import promotion from './promotion';

export default combineReducers({
  auth,
  vehicles,
  pricelist,
  discounts,
  promotion,
});
