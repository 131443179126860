/* eslint-disable react/display-name */
import React from "react";
import { IMAGE_BASE_URL } from "client/api/index";
import { useDropzone } from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ImageCropper from "components/ImageCropper";
import { CircularProgress } from "@material-ui/core";
import { CarouselRoutes } from "client";

const useStyles = makeStyles({
  mainContainer: {
    border: "1px dashed lightgray",
  },
  DragAndDropContainer: {
    width: 250,
    height: 'auto',
    cursor: 'pointer',
    margin: 'auto',
    alignItems: "center",
  },
  cardImageTop: {
    width: 250,
		height: 200,
    objectFit:'cover',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  }
})

const UploadCroppedImage = props => {
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [imageData, setImageData] = React.useState({
    preview: "",
    newImage: false,
  });
  const [cropper, setCropper] = React.useState({
    openCropper: false,
    imageUrl: "",
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/png", "image/bmp", "image/svg+xml"],
    multiple: false,
    onDrop: acceptedFiles => {
      const data = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
      setFiles(data);
      handleCropper(data[0].preview)
    },
  });

  const handleCropper = (url) => {
    setCropper({
      ...cropper,
      openCropper: true,
      imageUrl: url
    })
  }

  const handleCropImage = (value) => {
    let imgData = value.blob

    if (!imgData.name.includes('.')) {
      imgData.name = files[0].name
    }
    setImageData({
      ...imageData,
      newImage: true 
    })
    
    const data = new FormData();
    data.append('image', imgData, imgData.name);

    CarouselRoutes.uploadImage(data).then(file => {
      setImageData({
        ...imageData,
        preview: file.data.image,
        newImage: false
      })
      props.onHandleChange(file.data.image)
    })
  }

  const closeCropModal = () => {
    setCropper({
      ...cropper,
      openCropper: false,
    })
  }

  const renderIconImage = () => {
    const { imagePreview, style } = props;

    if(imageData.newImage){
      return <div className={classes.loader}><CircularProgress /></div>
    }

    if(imageData.preview) {
      return <img className={classes.cardImageTop} style={style} src={IMAGE_BASE_URL + imageData.preview} alt="body-type" onClick={() => handleCropper(imageData.preview)}/>
    }else {
      if(imagePreview) {
        if(imagePreview){
          return <img className={classes.cardImageTop} style={style} src={imagePreview} alt="body-type" onClick={() => handleCropper(imagePreview)} />
        }
      }
    }
  }

  const { aspectRatio } = props;
  return  (
    <div className={classes.mainContainer}>
      <div
        className={classes.DragAndDropContainer}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" align="center">
          Drag &apos;n&apos; drop some files here, or click to select
          files.
        </Typography>
      </div>
      {renderIconImage()}
       <ImageCropper
        previewImg={cropper.imageUrl && cropper.imageUrl}
        isOpen={cropper.openCropper}
        aspectRatio={aspectRatio}
        handleClose={closeCropModal}
        handleCrop={handleCropImage}
      />
    </div>
  )
}

export default UploadCroppedImage;