// Icons
import BuildIcon from '@material-ui/icons/Build';
import CarIcon from '@material-ui/icons/DirectionsCar';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';
import HeroImagesIcon from '@material-ui/icons/AspectRatio';
import BodyTypeIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import DehazeIcon from '@material-ui/icons/Dehaze';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

// Components
import Admins from 'views/Admins/Admins.js';
import CarsList from 'views/CarsList/CarsList';
import FinanceInfoPins from 'views/FinanceInfoPins/FinanceInfoPins';
import HomePageBanners from 'views/HomePage/HomePage';
import HeroImages from 'views/HeroImages/HeroImages';
import Carousel from 'views/Carousel';
import BodyTypeCards from 'views/BodyTypeCards/BodyTypeCards';
import PriceList from 'views/PriceList';
import Category from 'views/Category/Category';
import MenuItems from 'views/MenuItems/MenuItems';
import ReferralProgram from 'views/ReferalProgram';

const dashboardRoutes = [
  {
    path: '/cars-list',
    name: 'Cars list',
    icon: CarIcon,
    component: CarsList,
    layout: '/admin',
  },
  {
    path: '/admins',
    name: 'Admins',
    icon: BuildIcon,
    component: Admins,
    layout: '/admin',
  },
  {
    path: '/finance-info-pins',
    name: 'Finance Info Pins',
    icon: NotListedLocationIcon,
    component: FinanceInfoPins,
    layout: '/admin',
  },
  {
    path: '/home-page',
    name: 'Home Page Promo',
    icon: HomeIcon,
    component: HomePageBanners,
    layout: '/admin',
  },
  {
    path: '/hero-images',
    name: 'Hero Images',
    icon: HeroImagesIcon,
    component: HeroImages,
    layout: '/admin',
  },
  {
    path: '/carousel',
    name: 'Carousel',
    icon: BodyTypeIcon,
    component: Carousel,
    layout: '/admin',
  },
  {
    path: '/type-setup',
    name: 'Body Type Settings',
    icon: SettingsIcon,
    component: BodyTypeCards,
    layout: '/admin',
  },
  {
    path: '/price-list',
    name: 'Price List & Discounts',
    icon: MoneyIcon,
    component: PriceList,
    layout: '/admin',
  },
  {
    path: '/categories',
    name: 'Categories',
    icon: AllInclusiveIcon,
    component: Category,
    layout: '/admin',
  },
  {
    path: '/menu-items',
    name: 'Menu Items',
    icon: DehazeIcon,
    component: MenuItems,
    layout: '/admin',
  },
  {
    path: '/promotion',
    name: 'Referal Program',
    icon: SupervisorAccountIcon,
    component: ReferralProgram,
    layout: '/admin',
  },
];

export default dashboardRoutes;
