/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { IMAGE_BASE_URL } from "client/api/index";

import MaterialTable from "material-table";

import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
// NOTE: uncomment when we need local URL's
// import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useToasts } from "react-toast-notifications";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { HeroRoutes, CarouselRoutes } from "client";
import ColorDialog from "views/Carousel/ColorDialog";
import ImageUploaderWithCropper from "views/HomePage/ImageUploaderWithCropper";

const useStyles = makeStyles({
  addColorButton: {
    top: -25,
    cursor: "pointer",
    display: "flex",
    position: "relative",
    justifyContent: "flex-end"
  }
});

const HeroImages = () => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const tableColumns = [
    {
      title: "Title",
      field: "title",
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          value={props.rowData.title}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      )
    },
    {
      title: "Text",
      field: "text",
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          value={props.rowData.text}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      )
    },
    {
      title: "Visible",
      field: "visible",
      cellStyle: {
        maxWidth: 30,
        padding: 10,
        margin: 10
      },
      headerStyle: {
        padding: 10,
        margin: 10,
        maxWidth: 30
      },
      editComponent: props => (
        <Switch
          checked={props.rowData.visible}
          onChange={event => {
            props.onChange(event.target.checked, props.rowData);
          }}
          value={props.rowData.visible}
        />
      ),
      render: rowData => (
        <Switch checked={rowData.visible} value={rowData.visible} />
      )
    },
    {
      title: "Link Path",
      field: "linkPath",
      editComponent: props => (
        <TextField
          value={props.rowData.linkPath}
          fullWidth
          multiline
          // NOTE: Uncomment when we need local URL's
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment style={{ fontSize: 12 }} position="start">
          //       motopia.com
          //     </InputAdornment>
          //   )
          // }}
          placeholder="/about"
          onChange={event => {
            props.onChange(
              // NOTE: uncomment when we do not need whole URL
              //event.target.value.split(".com").pop(),
              event.target.value,
              props.rowData
            );
          }}
        />
      )
    },
    {
      title: "Image",
      field: "img",
      render: rowData => (
        <div
          style={{
            width: 150,
            height: 150,
            backgroundImage: `url(${IMAGE_BASE_URL + rowData.src})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
          }}
        />
      ),
      editComponent: props => (
        <ImageUploaderWithCropper
          onHandleChange={file => {
            props.onChange(file, props.rowData);
          }}
          aspectRatio={16 / 4.98}
        />
      )
    },
    {
      title: "Mobile Image",
      field: "mobileImg",
      render: rowData => (
        <div
          style={{
            width: 150,
            height: 150,
            backgroundImage: `url(${IMAGE_BASE_URL + rowData.mobileSrc})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
          }}
        />
      ),
      editComponent: props => (
        <ImageUploaderWithCropper
          onHandleChange={file => {
            props.onChange(file, props.rowData);
          }}
          aspectRatio={16 / 16}
        />
      )
    }
  ];

  const [state, setState] = React.useState({
    data: [],
    file: ""
  });
  const [openSetting, setOpenSetting] = React.useState(false);
  const [carousalSetting, setCarousalSetting] = React.useState({
    color: "#000000",
    bgColor: "#ffffff",
    slideSpeed: "300"
  });

  const loadBanners = async () => {
    try {
      const response = await HeroRoutes.getPromo();
      // NOTE: instead new route in API we separate banners via positions numbers
      setState({
        ...state,
        data: response.data
      });
    } catch (err) {
      const message = err.response.data.message || err.response.message || err.message || err;
      addToast(message, { severity: "info" });
    }
  };

  const getHeroCarousalData = async () => {
    try {
      const heroSetting = await CarouselRoutes.getHeroCarouselSetting();
      const heroCarousalData = heroSetting.data;
      setCarousalSetting({
        ...carousalSetting,
        color: heroCarousalData.textColor,
        bgColor: heroCarousalData.backgroundColor,
        slideSpeed: heroCarousalData.sliderSpeed
      });
    } catch (err) {
      addToast(err.status, { severity: "error" });
    }
  }

  React.useEffect(() => {
    loadBanners();
    getHeroCarousalData();
    // eslint-disable-next-line
  }, []);

  const getFormData = object =>
  Object.keys(object).reduce((formData, key) => {
    if (object[key] && object[key].name) {
      formData.append(key, object[key], object[key].name)
    } else {
      formData.append(key, object[key])
    }
    return formData;
  }, new FormData());

  const handleChangeBanner = async (newData, oldData) => {
    // TODO: filter data before patch
    const filteredData = [newData].filter(field => ![oldData].includes(field));
    const form = getFormData(...filteredData);
    try {
      await HeroRoutes.updatePromo(form, ...filteredData);
    } catch (err) {
      addToast(err.response.data.message, { severity: 'error' });
    }
    loadBanners();
  };

  const handleCloseCarouselSetting = () => {
    setOpenSetting(false)
  }

  const handleColorSetting = () => {
    setOpenSetting(true)
  }

  const updatedCarouselSetting = (data) => {
    setCarousalSetting({
      ...carousalSetting,
      color: data.text,
      bgColor: data.bgColor,
      slideSpeed: data.slideSpeed,

    })

    const settingData = {
      backgroundColor: data.bgColor,
      textColor: data.text,
      sliderSpeed: data.slideSpeed,
    }

    CarouselRoutes.updateHeroCarouselSetting(settingData).then(res => {
       addToast(res.status, { severity: 'success' });
    })
    handleCloseCarouselSetting();
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div  className={classes.addColorButton}>
          <Button
            onClick={handleColorSetting}
            variant="outlined"
            color="primary"
          >
            Setting
          </Button>
        </div>
        <MaterialTable
          title="Hero Images"
          columns={tableColumns}
          data={state.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              handleChangeBanner(newData, oldData)
          }}
        />
        <ColorDialog
          isOpen={openSetting}
          categorySetting={carousalSetting}
          headerTitle={"Carousel Setting"}
          handleClose={handleCloseCarouselSetting}
          updateSetting={updatedCarouselSetting}
        />
      </GridItem>
    </GridContainer>
  );
}

HeroImages.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired
};

HeroImages.defaultProps = {
  value: ""
};

export default HeroImages;