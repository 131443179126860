import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { listOfVehiclesSelector } from 'redux/selectors';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MaterialTable from 'material-table';
import SyncIcon from '@material-ui/icons/Sync';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { VehicleRoutes } from 'client';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Typography from '@material-ui/core/Typography';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';
const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      float: 'right',
      zIndex: 23,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  VehicleCountClass: {
    fontSize: 20,
    fontWeight: 500,
    padding: 5,
  },
  searchBarTextField: {
    float: 'right',
    zIndex: 25,
  },
  searchIc: {
    float: 'right',
    zIndex: 25,
    cursor: 'pointer',
  },
  ExportIc: {
    cursor: 'pointer',
    zIndex: 25,
    float: 'right',
    fontSize: 15,
    display: 'flex',
    border: 'none',
  },
}));
const renderPicture = vehicleData => {
  const vehicleImageArray = vehicleData.picturesUrl.sort(
    (a, b) =>
      a.name.split('.')[0].split('_')[
        a.name.split('.')[0].split('_').length - 1
      ] -
      b.name.split('.')[0].split('_')[
        b.name.split('.')[0].split('_').length - 1
      ]
  );

  return vehicleImageArray.length ? (
    <img
      src={vehicleImageArray[0].picture}
      style={{
        height: 100,
        width: 140,
      }}
      alt="vehicle preview"
    />
  ) : (
    <p>No picture available</p>
  );
};
const renderCheckBox = (isCheck, clicked) => {
  return (
    <Checkbox
      checked={isCheck.active}
      disabled={clicked}
      color="default"
      style={{ justifyContent: 'center' }}
      inputProps={{ 'aria-label': 'checkbox with default color' }}
    />
  );
};

const renderDateTime = data => {
  return dateFormat(data, 'mm/ dd/ yy');
};
export default connect(
  createStructuredSelector({
    listOfVehicles: listOfVehiclesSelector,
  })
)(function CarsList(props) {
  const classes = useStyles();
  const history = useHistory();
  let CarListPage = [50, 100, 200];
  let tableRef = React.createRef();
  const { addToast } = useToasts();
  const [clicked, setClicked] = React.useState(false);
  const [CurrentPage, setCurrentPage] = React.useState(0);
  const [sortCheck, setSortCheck] = React.useState(true);
  const [state, setState] = React.useState({
    isLoading: false,
    columns: [
      { title: 'ID', field: 'stockid' },
      {
        title: 'Year',
        field: 'carYear',
        cellStyle: {
          width: 10,
        },
      },
      {
        title: 'Make',
        field: 'make',
        cellStyle: {
          width: 120,
        },
      },
      { title: 'Model', field: 'model' },
      { title: 'Series', field: 'series' },
      { title: 'Mileage', field: 'mileage' },
      { title: 'Ride-Share', field: 'rideShareCategory' },
      { title: 'LifeStyle', field: 'lifeStyleCategory' },
      {
        title: 'Purchase Date',
        field: 'purchaseDate',
        render: data => renderDateTime(data.purchaseDate),
      },
      {
        title: 'Possible Features',
        field: 'features.possibleFeatures.length',
        headStyle: {
          textAlign: 'left',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'Installed Features',
        field: 'features.installedPossibleFeatures.length',
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'INST FTR Pins',
        field: 'vehiclePinCount.instFeatureCount',
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'FTR Pins',
        field: 'vehiclePinCount.featureCount',
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'DMG Pins',
        field: 'vehiclePinCount.damageCount',
        cellStyle: {
          textAlign: 'center',
        },
      },
      { title: 'Price', field: 'listPrice' },
      {
        title: 'Picture',
        field: 'picturesUrl',
        render: data => renderPicture(data),
      },
    ],
    listOfVehicles: [],
    totalCount: 0,
    UpdatedCount: 0,
    feVehicleCount: 0,
    searchInputValue: '',
    LayeredVehiclesCount: 0,
  });

  const handleCheck = async data => {
    setState({ ...state, isLoading: true });
    let isCheck = null;
    if (clicked === true) {
      return;
    }
    setClicked(true);

    if (data && data.active === true) {
      isCheck = false;
    } else {
      isCheck = true;
    }
    try {
      await VehicleRoutes.updateVehicleById(data._id, {
        ...data,
        active: isCheck,
      }).then(resData => {
        setClicked(false);
        setState({
          ...state,
          listOfVehicles: state.listOfVehicles.map(vehicleData =>
            vehicleData._id === resData.data._id
              ? { ...resData.data }
              : vehicleData
          ),
          feVehicleCount: resData.FeVehiclesCount,
          isLoading: false,
        });
      });
    } catch (error) {
      setClicked(false);
      addToast(error.status, { severity: 'error' });
    }
  };

  const sortCheckList = async () => {
    setSortCheck(!sortCheck);
    setState({ ...state, isLoading: true });
    let sortedParams = {
      orderCol: 'active',
      orderDirec: sortCheck === true ? 1 : -1,
    };
    try {
      window.localStorage.setItem('PageSize', 50);
      window.localStorage.setItem('PageDefault', 0);
      window.localStorage.setItem('orderCol', sortedParams.orderCol);
      window.localStorage.setItem('orderDirec', sortedParams.orderDirec);
      const params = {
        orderCol: sortedParams.orderCol,
        orderDirec: sortedParams.orderDirec,
        querySearch: window.localStorage.getItem('searchVal'),
        pageSize: 50,
        page: 0,
      };
      const response = await VehicleRoutes.getVehicles(params);
      setState({
        ...state,
        isLoading: false,
        listOfVehicles: [...response.data],
      });
      setCurrentPage(0);
    } catch (err) {
      addToast(err.status, { severity: 'error' });
    }
  };
  // NOTE: calling backend with null values
  // React.useEffect(() => {
  //   setState({ ...state, listOfVehicles: [...props.listOfVehicles] });
  //   tableRef.current && tableRef.current.onQueryChange()
  //   vehicleWithLayer(state.listOfVehicles);
  //   // eslint-disable-next-line
  // }, [props.listOfVehicles]);

  React.useEffect(() => {
    const scopedRef = tableRef.current;
    loadVehicle().then(() => scopedRef.onQueryChange());
    vehicleWithLayer(state.listOfVehicles);
    // eslint-disable-next-line
  }, []);

  const loadVehicle = async () => {
    setState({ ...state, isLoading: true });
    try {
      let defaultVal = window.localStorage.getItem('searchVal');
      window.localStorage.setItem('PageSize', 50);
      let pageExist = window.localStorage.getItem('PageDefault');
      const params = {
        orderCol: window.localStorage.getItem('orderCol'),
        orderDirec: window.localStorage.getItem('orderDirec'),
        querySearch: defaultVal == null ? '' : defaultVal,
        page: pageExist ? pageExist : 0,
        pageSize: 50,
      };
      const response = await VehicleRoutes.getVehicles(params);
      await setState({
        ...state,
        isLoading: false,
        listOfVehicles: [...response.data],
        totalCount: response.totalResults.VehiclesCount,
        LayeredVehiclesCount: response.totalResults.LayeredVehiclesCount,
        UpdatedCount: response.totalResults.UpdatedCount,
        feVehicleCount: response.totalResults.FeVehiclesCount,
      });
      setCurrentPage(Number(pageExist));
    } catch (err) {
      addToast(err.status, { severity: 'error' });
    }
  };

  const Icon = () => {
    function refreshPage() {
      window.location.reload();
    }

    const updateList = async () => {
      addToast('Process is running', { autoDismissTimeout: 1000 });
      setState({ ...state, isLoading: true });
      try {
        await VehicleRoutes.updateVehicles();
        addToast(
          "Success! This page will reload in 30 sec. DON'T CLOSE THIS PAGE",
          {
            autoDismissTimeout: 30000,
            severity: 'info',
          }
        );
        setTimeout(refreshPage, 30000);
      } catch (error) {
        console.error(error);
        addToast('Fail! Try again in 2 minutes', { severity: 'error' });
      }
    };

    return <SyncIcon onClick={updateList} />;
  };

  const vehicleWithLayer = () => {
    let hasLayer = 0;
    if (state.listOfVehicles.length > 0) {
      let listOfVehicles = [];
      listOfVehicles = state.listOfVehicles;
      let i = 0;
      for (i; i < listOfVehicles.length; i += 1) {
        if (listOfVehicles[i].vehiclePinCount != null) {
          if (listOfVehicles[i].vehiclePinCount.damageCount > 0) {
            hasLayer = hasLayer + 1;
          } else if (listOfVehicles[i].vehiclePinCount.featureCount > 0) {
            hasLayer = hasLayer + 1;
          } else if (listOfVehicles[i].vehiclePinCount.instFeatureCount > 0) {
            hasLayer = hasLayer + 1;
          }
        }
      }
      return hasLayer;
    } else {
      return hasLayer;
    }
  };
  const nextPage = async (pageNum, pageSize) => {
    setCurrentPage(pageNum);
    window.localStorage.setItem('PageDefault', pageNum);
    window.localStorage.setItem('PageSize', pageSize);
    setState({ ...state, isLoading: true });
    try {
      const params = {
        orderCol: window.localStorage.getItem('orderCol'),
        orderDirec: window.localStorage.getItem('orderDirec'),
        querySearch: window.localStorage.getItem('searchVal'),
        page: pageNum,
        pageSize: window.localStorage.getItem('PageSize'),
      };
      const response = await VehicleRoutes.getVehicles(params);
      await setState({
        ...state,
        isLoading: false,
        listOfVehicles: [...response.data],
        totalCount: response.totalResults.VehiclesCount,
      });
    } catch (err) {
      addToast(err.status, { severity: 'error' });
    }
  };
  const handleKeyPress = async event => {
    if (event.key === 'Enter') {
      const scopedRef = tableRef.current;
      sendSearchQuery().then(() => scopedRef.onQueryChange());
    }
  };
  const sendSearchQuery = async () => {
    setState({ ...state, isLoading: true });
    try {
      window.localStorage.setItem('PageSize', 50);
      window.localStorage.setItem('PageDefault', 0);
      window.localStorage.removeItem('orderCol', 'orderDirec');
      const params = {
        querySearch: window.localStorage.getItem('searchVal'),
        pageSize: 50,
        page: 0,
      };
      const response = await VehicleRoutes.getVehicles(params);
      setState({
        ...state,
        isLoading: false,
        listOfVehicles: [...response.data],
        UpdatedCount: response.totalResults.UpdatedCount,
      });
      setCurrentPage(0);
    } catch (err) {
      addToast(err.status, { severity: 'error' });
    }
  };
  const handleSearchInput = async event => {
    let searchVal = event.target.value;
    window.localStorage.setItem('searchVal', searchVal);
  };
  const openNewTab = data => {
    window.open('/admin/vehicle?id=' + data, '_blank');
  };
  let defaultSearchValue = window.localStorage.getItem('searchVal');
  const sortData = async (orderBy, orderDirection) => {
    if (orderBy !== 11 && orderBy !== 12 && orderBy !== 13 && orderBy !== 15) {
      let sortedParams = switchData(orderBy, orderDirection);
      setState({ ...state, isLoading: true });
      try {
        window.localStorage.setItem('PageSize', 50);
        window.localStorage.setItem('PageDefault', 0);
        window.localStorage.setItem('orderCol', sortedParams.orderCol);
        window.localStorage.setItem('orderDirec', sortedParams.orderDirec);
        const params = {
          orderCol: sortedParams.orderCol,
          orderDirec: sortedParams.orderDirec,
          querySearch: window.localStorage.getItem('searchVal'),
          pageSize: 50,
          page: 0,
        };
        const response = await VehicleRoutes.getVehicles(params);
        setState({
          ...state,
          isLoading: false,
          listOfVehicles: [...response.data],
          UpdatedCount: response.totalResults.UpdatedCount,
        });
        setCurrentPage(0);
      } catch (err) {
        addToast(err.status, { severity: 'error' });
      }
    }
  };
  const switchData = (orderBy, orderDirection) => {
    if (orderDirection === 'asc') {
      switch (orderBy) {
        case 0:
          return {
            orderCol: 'stockid',
            orderDirec: 1,
          };
        case 1:
          return {
            orderCol: 'carYear',
            orderDirec: 1,
          };
        case 2:
          return {
            orderCol: 'make',
            orderDirec: 1,
          };
        case 3:
          return {
            orderCol: 'model',
            orderDirec: 1,
          };
        case 4:
          return {
            orderCol: 'series',
            orderDirec: 1,
          };
        case 5:
          return {
            orderCol: 'mileage',
            orderDirec: 1,
          };
        case 6:
          return {
            orderCol: 'rideShareCategory',
            orderDirec: 1,
          };
        case 7:
          return {
            orderCol: 'lifeStyleCategory',
            orderDirec: 1,
          };
        case 8:
          return {
            orderCol: 'purchaseDate',
            orderDirec: 1,
          };
        case 9:
          return {
            orderCol: 'possibleFeatures',
            orderDirec: 1,
          };
        case 10:
          return {
            orderCol: 'installedPossibleFeatures',
            orderDirec: 1,
          };
        case 11:
          return {
            orderCol: 'POSSIBLE FEATURE',
            orderDirec: 1,
          };
        case 12:
          return {
            orderCol: 'FEATURE',
            orderDirec: 1,
          };
        case 13:
          return {
            orderCol: 'DAMAGE',
            orderDirec: 1,
          };
        case 14:
          return {
            orderCol: 'listPrice',
            orderDirec: 1,
          };
        default:
          return {
            orderCol: 'purchaseDate',
            orderDirec: -1,
          };
      }
    } else {
      switch (orderBy) {
        case 0:
          return {
            orderCol: 'stockid',
            orderDirec: -1,
          };
        case 1:
          return {
            orderCol: 'carYear',
            orderDirec: -1,
          };
        case 2:
          return {
            orderCol: 'make',
            orderDirec: -1,
          };
        case 3:
          return {
            orderCol: 'model',
            orderDirec: -1,
          };
        case 4:
          return {
            orderCol: 'series',
            orderDirec: -1,
          };
        case 5:
          return {
            orderCol: 'mileage',
            orderDirec: -1,
          };
        case 6:
          return {
            orderCol: 'rideShareCategory',
            orderDirec: -1,
          };
        case 7:
          return {
            orderCol: 'lifeStyleCategory',
            orderDirec: -1,
          };
        case 8:
          return {
            orderCol: 'purchaseDate',
            orderDirec: -1,
          };
        case 9:
          return {
            orderCol: 'possibleFeatures',
            orderDirec: -1,
          };
        case 10:
          return {
            orderCol: 'installedPossibleFeatures',
            orderDirec: -1,
          };
        case 11:
          return {
            orderCol: 'POSSIBLE FEATURE',
            orderDirec: -1,
          };
        case 12:
          return {
            orderCol: 'FEATURE',
            orderDirec: -1,
          };
        case 13:
          return {
            orderCol: 'DAMAGE',
            orderDirec: -1,
          };
        case 14:
          return {
            orderCol: 'listPrice',
            orderDirec: -1,
          };
        default:
          return {
            orderCol: 'purchaseDate',
            orderDirec: -1,
          };
      }
    }
  };
  const exportData = async () => {
    try {
      const response = await VehicleRoutes.exportInventory();
      addToast(response.message);
    } catch (err) {
      addToast(err.status, { severity: 'error' });
    }
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Typography className={classes.VehicleCountClass}>
          Total Vehicles: {state.totalCount}
          <span style={{ padding: '30px' }}>
            Total Layered Vehicles: {state.LayeredVehiclesCount}
          </span>
          <span style={{ padding: '30px' }}>
            % Layered Vehicles:{' '}
            {((state.LayeredVehiclesCount * 100) / state.totalCount).toFixed(2)}
          </span>
          <span style={{ padding: '30px' }}>
            Vehicles on FE: {state.feVehicleCount}
          </span>
          <button
            className={classes.ExportIc}
            display="Export CMS Data"
            onClick={() => exportData()}
            title="Export Vehicle List"
          >
            <ImportExportIcon />
          </button>
          <Tooltip title="Search">
            <SearchIcon
              className={classes.searchIc}
              onClick={() => {
                const scopedRef = tableRef.current;
                sendSearchQuery().then(() => scopedRef.onQueryChange());
              }}
            />
          </Tooltip>
          <TextField
            onKeyPress={handleKeyPress}
            className={classes.searchBarTextField}
            defaultValue={defaultSearchValue}
            onChange={handleSearchInput}
            placeholder="Search"
          />
        </Typography>
        <MaterialTable
          isLoading={state.isLoading}
          title="Vehicle list"
          columns={state.columns}
          tableRef={tableRef}
          data={async query =>
            await new Promise((resolve, reject) => {
              resolve({
                data: state.listOfVehicles,
                page: CurrentPage,
                totalCount: state.UpdatedCount,
              });
            })
          }
          onOrderChange={(orderBy, orderDirection) => {
            const scopedRef = tableRef.current;
            sortData(orderBy, orderDirection).then(() =>
              scopedRef.onQueryChange()
            );
          }}
          onRowClick={(event, vehicle) =>
            history.push(`/admin/vehicle?id=${vehicle.stockid}`)
          }
          onChangePage={pageNum => {
            const scopedRef = tableRef.current;
            nextPage(
              pageNum,
              Number(window.localStorage.getItem('PageSize'))
            ).then(() => scopedRef.onQueryChange());
          }}
          onChangeRowsPerPage={pageSize => {
            const scopedRef = tableRef.current;
            nextPage(CurrentPage, pageSize).then(() =>
              scopedRef.onQueryChange()
            );
          }}
          options={{
            pageSize: Number(window.localStorage.getItem('PageSize')),
            pageSizeOptions: CarListPage,
            search: false,
            fixedColumns: {
              left: 1,
            },
          }}
          actions={[
            {
              icon: () => <Icon />,
              isFreeAction: true,
              tooltip: 'Refersh vehicle list',
            },
            {
              icon: () => <OpenInNewIcon />,
              tooltip: 'Open stock in new tab',
              onClick: (event, rowData) => openNewTab(rowData.stockid),
            },
            rowData => ({
              icon: () => renderCheckBox(rowData, clicked),
              onClick: (event, rowData) => {
                const scopedRef = tableRef.current;
                handleCheck(rowData, rowData._id).then(() =>
                  scopedRef.onQueryChange()
                );
              },
            }),
          ]}
          localization={{
            header: {
              actions: (
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexDirection: 'inherit',
                    justifyContent: 'flex-start',
                    pointerEvents: 'auto',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const scopedRef = tableRef.current;
                    sortCheckList().then(() => scopedRef.onQueryChange());
                  }}
                >
                  <div>Show on FE </div>
                </span>
              ),
            },
          }}
        />
      </GridItem>
    </GridContainer>
  );
});
