import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { createStructuredSelector } from 'reselect';

import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';

import { PricesRoutes, PromotionRoutes } from 'client';
import { getPrices } from '../../redux/actions/prices';
import { getDiscounts } from '../../redux/actions/discounts';
import { priceListSelector, discountsSelector } from '../../redux/selectors';

const PriceList = props => {
  const { addToast } = useToasts();

  useEffect(() => {
    props.getPrices();
    props.getDiscounts();
    // eslint-disable-next-line
  }, []);

  const priceTableColumns = [
    {
      title: 'Lock down payment, $',
      field: 'lockDown',
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="3"
          name="lockDown"
          value={props.rowData.lockDown}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      ),
    },
    {
      title: 'Minimal Downpayment, $',
      field: 'downPayment',
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="3"
          name="downPayment"
          value={props.rowData.downPayment}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      ),
    },
    {
      title: 'Retail Deposit, $',
      field: 'retailDeposit',
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="3"
          name="retailDeposit"
          value={props.rowData.retailDeposit}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      ),
    },
    {
      title: 'Delivery Fee, $',
      field: 'deliveryFee',
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="3"
          name="deliveryFee"
          value={props.rowData.deliveryFee}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      ),
    },
  ];

  const discountTableColumns = [
    {
      title: 'Discount for Sale, $',
      field: 'saleDiscount',
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="3"
          name="saleDiscount"
          value={props.rowData.saleDiscount}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      ),
    },
    {
      title: 'Discount for Rent, $',
      field: 'rentDiscount',
      editComponent: props => (
        <TextField
          fullWidth
          multiline
          rowsMax="3"
          name="rentDiscount"
          value={props.rowData.rentDiscount}
          onChange={event => {
            props.onChange(event.target.value, props.rowData);
          }}
        />
      ),
    },
  ];

  const handleChangePrices = async newData => {
    const obj = {
      _id: newData._id,
      lockDown: String(newData.lockDown),
      downPayment: String(newData.downPayment),
      retailDeposit: String(newData.retailDeposit),
      deliveryFee: String(newData.deliveryFee),
    };
    try {
      const response = await PricesRoutes.setPriceList(obj);
      addToast(response.status);
    } catch (err) {
      addToast(err.response.data.message, { severity: 'error' });
    }
    props.getPrices();
  };

  const handleChangeDiscounts = async newData => {
    const obj = {
      _id: newData._id,
      saleDiscount: String(newData.saleDiscount),
      rentDiscount: String(newData.rentDiscount),
    };
    try {
      const response = await PromotionRoutes.setDiscounts(obj);
      addToast(response.status);
    } catch (err) {
      addToast(err.response.data.message, { severity: 'error' });
    }
    props.getDiscounts();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MaterialTable
          title="Price List"
          columns={priceTableColumns}
          data={props.prices}
          editable={{
            onRowUpdate: newData => handleChangePrices(newData),
          }}
          options={{
            paging: false,
            pageSize: 1,
            search: false,
            actionsColumnIndex: -1,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MaterialTable
          title="Referral Discounts"
          columns={discountTableColumns}
          data={props.discounts}
          editable={{
            onRowUpdate: newData => handleChangeDiscounts(newData),
          }}
          options={{
            paging: false,
            pageSize: 1,
            search: false,
            actionsColumnIndex: -1,
          }}
        />
      </Grid>
    </Grid>
  );
};

PriceList.propTypes = {
  prices: PropTypes.array.isRequired,
  getPrices: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  prices: priceListSelector,
  discounts: discountsSelector,
});

const mapDispatchToProps = {
  getPrices,
  getDiscounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceList);
