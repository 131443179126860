import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import store from "redux/store";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import Vehicle from "views/Vehicle/Vehicle";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import { getListOfVehicles } from "redux/actions/vehicles";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route path="/admin/vehicle" component={Vehicle} />
    <Redirect from="/" to="/admin/cars-list" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [state, setState] = React.useState({
    mobileOpen: false
  });

  const handleDrawerToggle = () => {
    setState({ ...state, mobileOpen: !state.mobileOpen });
  };

  React.useEffect(() => {
    store.dispatch(getListOfVehicles());
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"MOTOPIA ADMIN"}
        handleDrawerToggle={handleDrawerToggle}
        open={state.mobileOpen}
        color={"blue"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      </div>
    </div>
  );
}
