import React, { useState } from "react";
import PropTypes from "prop-types";
import { IMAGE_BASE_URL } from "client/api/index";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UploadCroppedImage from "./UploadCroppedImage";

const useStyles = makeStyles({
  variantContainer: {
    margin: "10px 0px",
		padding: "8px 0px",
    borderTop: "1px solid #001C5E"
  },
  viewAllContainer: {
    padding: "8px 0px"
  },
  addedVariantContainer: {
    margin: "8px 0px"
  },
  addedVariantField: {
    paddingLeft: 16,
    overflowWrap: "break-word"
  },
  DragAndDropContainer: {
    width: 300,
    height: 70,
    margin: 12,
    border: "1px dashed lightgray",
    cursor: 'pointer',
  },
  uploadImageButton: {
    minWidth: 150,
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
	},
  cardImageTop: {
    width: 250,
		height: 200,
    objectFit:'cover',
  },
  cardImage: {
    width: 250,
		height: 200,
    objectFit:'cover',
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    padding: 4,
    boxSizing: "border-box"
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
  },
  imgPreview: {
    display: "block",
    width: 200,
    height: 150,
    objectFit: "contain"
  },
  categoryImgTitle: {
    padding: 10,
  },
  imagePreviewDiv: {
    textAlign: 'center',
  }
});

export default function BodyTypeAddDialog(props) {
  const classes = useStyles();
  const [imageData, setImageData] = useState({
    imageUrl: "",
    mobileImageUrl: "",
    newMobileImage: false,
    newImage: false,
  });
  const [state, setState] = React.useState({
    title: "",
    links: [],
		description: "",
		editDescription: true,
    mainLink: "",
    name: "",
    link: "",
    uploadingFileError: false
  });

  React.useEffect(() => {
    setState({ ...state, ...props.cardData, mainLink: props.cardData.link  });
    // eslint-disable-next-line
  }, []);

  const handleTextField = field => event =>
    setState({ ...state, [field]: event.target.value });

  const handleCropImage = (image) => {
      setImageData({
        ...imageData,
        imageUrl: image,
      })
  }

  const handleCropMobileImage = (image) => {
    setImageData({
      ...imageData,
      mobileImageUrl: image,
    })
}

  const handleUpdate = async () => {
    const { title, mainLink, description } = state;

    const image = imageData.imageUrl ? imageData.imageUrl : cardData.image;
    const logoImage = imageData.imageUrl ? imageData.imageUrl : cardData.logoImage;
    const mobileImage = imageData.mobileImageUrl ? imageData.mobileImageUrl : cardData.mobileImage;

    if(props.handleUpdateCategory){
      props.handleUpdateCategory({
        _id: props.cardData._id,
        title,
        image,
        mobileImage,
        description,
      }); 
    }

    if(props.handleUpdateSubCategory){
      props.handleUpdateSubCategory({
        _id: props.cardData._id,
        title,
        logoImage,
        description,
        link: mainLink,
      })
    }
  };

	const handleSubmit = async () => {
    const { title, mainLink, description } = state;

    const image = imageData.imageUrl ? imageData.imageUrl : cardData.image;
    const logoImage = imageData.imageUrl ? imageData.imageUrl : cardData.logoImage;
    const mobileImage = imageData.mobileImageUrl ? imageData.mobileImageUrl : cardData.logoImage;

    if(subCategoryMode){
      props.submit({
        title,
        logoImage,
        mainLink,
        description,
      });
    }else{
      props.submit({
        title,
        image,
        mobileImage,
        mainLink,
        description,
      });
    }
  };

  const { isOpen, cardData , headerTitle, submit, noViewLink, subCategoryMode, errors, imgCropper } = props;

  const desktopIcon = subCategoryMode ?
    (cardData.logoImage ? IMAGE_BASE_URL + cardData.logoImage : cardData.logoImage)
    : (cardData.image ? IMAGE_BASE_URL + cardData.image : cardData.image);
  const mobileIcon = cardData._id ? IMAGE_BASE_URL + cardData.mobileImage : cardData.mobileImage;
	
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      onBackdropClick={props.handleCloseModal}
    >
      <DialogTitle id="form-dialog-title">{headerTitle}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid container alignItems="center">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={state.title}
              onChange={handleTextField("title")}
            />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <div className={classes.imagePreviewDiv}>
              <Typography className={classes.categoryImgTitle}>{subCategoryMode ? "icon" : "Desktop icon"}</Typography>
              <UploadCroppedImage 
                onHandleChange={handleCropImage}
                imagePreview={desktopIcon}
                aspectRatio={subCategoryMode ? (16 / 9.09) : (21 / 6.9)}
                style={{cursor: imgCropper? 'move' : 'auto', width: 'auto', height: 160}}
              />
            </div>
            {!subCategoryMode ? 
            <div className={classes.imagePreviewDiv}>
              <Typography className={classes.categoryImgTitle}>Mobile icon</Typography>
              <UploadCroppedImage
                onHandleChange={handleCropMobileImage}
                imagePreview={mobileIcon}
                style={{cursor: imgCropper? 'move' : 'auto', width: 'auto', height: 160}}
                aspectRatio={16 / 16}
              />
            </div>
            : null}
          </Grid>
          <Grid
            className={classes.variantContainer}
            container
            direction="column"
          >
            <Typography gutterBottom variant="button" align="center">
              Add description
            </Typography>
						<Grid
							container
							justify="space-between"
							wrap="nowrap"
							alignItems="center"
						>
							<Grid item xs={12}>
							<TextField
									fullWidth
									label="Description"
									variant="outlined"
									value={state.description}
									onChange={handleTextField("description")}
								/>
							</Grid>
						</Grid>
          </Grid>
					{!noViewLink ?
						<Grid
							className={classes.viewAllContainer}
							container
							justify="space-between"
							wrap="nowrap"
							alignItems="center"
						>
							<Grid item xs={3}>
								<Typography style={{ paddingLeft: 16 }} variant="body1">
									Link
              </Typography>
							</Grid>
							<Grid item xs={9}>
								<TextField
									fullWidth
									label="Link"
									variant="outlined"
									value={state.mainLink}
									onChange={handleTextField("mainLink")}
								/>
							</Grid>
						</Grid>
						: ""
					}
          {errors ?
            <Typography variant="body1" color="error" style={{textTransform: "capitalize"}}>
              {errors}
            </Typography>
            : null
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseModal} color="primary">
          Close
        </Button>
        {submit ?
          <Button onClick={handleSubmit} color="primary">
            submit
				</Button>
          :
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

BodyTypeAddDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cardData: PropTypes.object.isRequired,
};
