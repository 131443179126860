/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";

import MaterialTable from "material-table";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { FinanceRoutes } from "client";
import TextField from "@material-ui/core/TextField";

export default function FinanceInfoPins() {
  const [state, setState] = React.useState({
    columns: [
      {
        title: "Position",
        field: "number",
        editComponent: props => <div> {props.rowData.number} </div>
      },
      {
        title: "Page",
        field: "page",
        editComponent: props => <div> {props.rowData.page} </div>
      },
      {
        title: "Description",
        field: "description",
        editComponent: props => (
          <TextField
            fullWidth
            value={props.rowData.description}
            onChange={event => {
              props.onChange(event.target.value, props.rowData);
            }}
          />
        )
      }
    ],
    data: []
  });

  const loadPins = async () => {
    let data;
    try {
      data = await FinanceRoutes.getPins();
    } catch (err) {
      alert(err);
    }
    setState({ ...state, data: [...data.data] });
  };

  React.useEffect(() => {
    loadPins();
    // eslint-disable-next-line
  }, []);

  const handleChangeFinancePin = async newData => {
    try {
      await FinanceRoutes.updatePin(newData);
    } catch (err) {
      alert(err);
    }
    loadPins();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <MaterialTable
          title="Finance Info Pins"
          columns={state.columns}
          data={state.data}
          editable={{
            onRowUpdate: newData => handleChangeFinancePin(newData)
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

FinanceInfoPins.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired
};
